export const INTEGRATED_CHECK_TYPES = ["non reference", "integrated", "neotas integrated check", "integrated check"];

// * In case integrated check is the same as the tool and require same licenses we need to set an integrated check name equal to the tool name
export const integratedCheckList = Object.freeze({
  EDD: "edd",
  // next values may be changed to any other:
  CORPORATE_RECORDS: "corporate records",
  BVD: "bvd",
  HOOYU: "hooyu",
  DBS_BASIC: "dbs basic",
  TRANSUNION: "transunion",
  LEXIS_BRIDGER: "lexis bridger",
  NEOTAS: "neotas",
  SEARCH_ENGINE: "search engine",
  DNB: "dnb",
  VLEX: "vlex",
  ORBIS: "orbis"
});

// different names for tools set custom values here
// (it shouldn't be equal to check name but can)
export const toolList = Object.freeze({
  EDD: integratedCheckList.EDD,
  SEARCH_ENGINE: "search-engine-tool",
});

export const integratedCheckIdList = Object.freeze({
  [integratedCheckList.EDD]:'92b5f0c9-f341-485e-9c70-87d6276491f8',
  [integratedCheckList.CORPORATE_RECORDS]: "07f9b595-958f-4402-907f-31d4ebb4a369",
  [integratedCheckList.HOOYU]: "2b1163fe-baec-431d-8d38-ef791cb623fe",
  [integratedCheckList.DBS_BASIC]: "36dfd7c8-5750-4ef4-a591-e441e7d1e0c8",
  [integratedCheckList.TRANSUNION]: "6db183f9-6b16-47d0-81d1-514728c4ff2f",
  [integratedCheckList.LEXIS_BRIDGER]: "8526f91f-5b51-4703-84d4-7f97ba785e42",
  [integratedCheckList.NEOTAS]:"b875f10c-3cf1-413b-bc08-971cf7a460a6",
  [integratedCheckList.SEARCH_ENGINE]: "bda2c73c-7490-4ae2-9b4a-2c1035d83f3d",
  [integratedCheckList.DNB]: "f8f5606d-826f-4c36-ba1a-a6781c914bc1",
  [integratedCheckList.VLEX]: "c173f677-4bf1-41bf-9edb-1aa41cd0fca7",
  [integratedCheckList.ORBIS]: "94d113c5-ecd4-4468-8e04-5d3a3d938d5c",
});

export const INTEGRATED_CHECK_ID_TO_TOOL_NAME_MAP = Object.freeze({
  [integratedCheckIdList[integratedCheckList.SEARCH_ENGINE]]: toolList.SEARCH_ENGINE,
});

export const TOOLS_LICENSE_MAP = {
  "lexis-bridger-tool": ["lexis_bridger__adverse_pep_sacntion"],
  "company-name": ["bvd__corp_record", "db__corp_record"],
  "search-engine-tool": ["search_engine__search_engine_"],
  "gdc-person": ["gdc__person"],
  "orbis-tool": ["orbis__corp_record"],
  [toolList.EDD]: ["edd"],
  ["vlex-tool"]: ["litigation_search__vlex_tool"],
  "world-compliance-tool": ["wco_data_file__adverse_pep_sanction"],
};

export const CHECK_TOOLS = [ "lexis-bridger-tool", "company-name", "search-engine-tool", "gdc-person",  "orbis-tool", "world-compliance-tool" , "vlex-tool"];

export const TOOL_TO_CHECK_NAME_MAP = {
  "company-name": ["corporate record", "corporate records", "poe", "dnb"],
  "lexis-bridger-tool": ["lexis bridger", "lexis-bridger", "kyc"],
  "orbis-tool": ["orbis"],
  "search-engine-tool": ["search engine"],
  "world-compliance-tool": ["worldcompliance", "world compliance"],
  "gdc-person": ["id verification"],
  "vlex-tool": ["vlex"],
};
